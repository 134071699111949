<template>
  <div>
    <vue-element-loading
      :active="validatingCardLocal && !preventAppLoading"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <form class="mb-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input
            v-model="model.name"
            class="w-full required"
            :label="$t('$Components.$AddCreditCard.CardholderName')"
            :name="$t('$Components.$AddCreditCard.CardholderName')"
            type="text"
            v-validate="'required|max:200'"
            data-vv-validate-on="blur|input"/>
          <span
            v-show="errors.has($t('$Components.$AddCreditCard.CardholderName'))"
            class="text-danger text-sm">
            {{ errors.first($t('$Components.$AddCreditCard.CardholderName')) }}
          </span>
        </div>
      </div>
    </form>

    <div
      id="card_container_v40j1nure"></div>
    <div>
      <span
        v-show="cardError || !!cardErrorProp"
        class="text-danger text-sm">{{ cardError || cardErrorProp }}</span>
    </div>

    <div
      v-if="addButton"
      class="flex justify-between mt-base">
      <vs-checkbox
        v-model="model.makeDefault">
        {{ $t('MakeDefaultCreditCard') }}
      </vs-checkbox>

      <vs-button
        :disabled="!isValidAddCard || validatingCardLocal"
        class="ml-3"
        size="small"
        @click="addCard()">
        {{ $t('$General.Add') }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import stripeService from '@/api/stripe.service';

/**
 * Component to allow user add new credit cards
 *
 * @module views/modules/components/AddCreditCard
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} [addButton=true] - indicate if show o no the add button
 * @vue-data {Object} [model={...}] - card model
 * @vue-data {Object<Element>} [cardElement=null] - stripe card element
 * @vue-data {string} [cardError=''] - card form error
 * @vue-data {boolean} [cardComplete=''] - indicate if the card form is complete
 * @vue-computed {boolean} isValidAddCard - indicate if valid add a new card
 * @vue-event {void} loadStripeCard - load stripe card element
 * @vue-event {void} cardElementChange - called on card form change
 * @vue-event {void} addCard - create the token to add a new card
 * @vue-event {void} saveBillingInformation - save billingInfoMode
 */
export default {
  name: 'AddCreditCard',
  i18n: {
    messages: {
      en: {
        MakeDefaultCreditCard: 'Make default',
      },
    },
  },
  props: {
    addButton: {
      type: Boolean,
      default: true,
    },
    cardErrorProp: {
      type: String,
      required: false,
      default: '',
    },
    validatingCard: {
      type: Boolean,
      default: false,
    },
    errorCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        name: '',
        token: '',
        makeDefault: false,
      },
      cardElement: null,
      cardError: '',
      cardComplete: false,
      validatingCardLocal: this.validatingCard,
    };
  },
  computed: {
    isValidAddCard() {
      return !this.errors.any()
        && this.cardError === ''
        && this.model.name !== ''
        && this.cardComplete;
    },
  },
  watch: {
    isValidAddCard(val) {
      this.$emit('is-valid', val);
    },
    validatingCard(val) {
      this.validatingCardLocal = val;
    },
    errorCard(val) {
      const $el = this.$el.querySelector('#card_container_v40j1nure');

      if (val) {
        $el.classList.add('error');
      } else {
        $el.classList.remove('error');
      }
    },
  },
  mounted() {
    this.loadStripeCard();
  },
  beforeDestroy() {
    this.cardElement.destroy();
  },
  methods: {
    async loadStripeCard() {
      await stripeService.loadStripe();
      this.cardElement = stripeService.elements.create('card', {
        hidePostalCode: true,
        classes: {
          base: 'grape-send__stripe-add-card-form',
        },
        style: {
          base: {
            fontWeight: '400',
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            color: '#2c2c2c',
            ':-webkit-autofill': {
              color: '#2c2c2c',
            },
            '::placeholder': {
              color: '#626262',
            },
          },
          invalid: {
            iconColor: 'rgba(234,84,85,1)',
            color: 'rgba(234,84,85,1)',
          },
        },
      });
      this.cardElement.mount('#card_container_v40j1nure');
      this.cardElement.on('change', this.cardElementChange);
    },
    cardElementChange(event) {
      this.cardComplete = event.complete;
      this.cardError = event.error ? event.error.message : '';
      this.$emit('change');
    },
    async addCard() {
      this.validatingCardLocal = true;
      const result = await stripeService.stripe.createToken(this.cardElement);

      if (result.error) {
        this.cardError = result.error.message;
        this.validatingCardLocal = false;

        return result;
      }

      this.model.token = result.token.id;
      this.$emit('add-card', this.model);
      this.validatingCardLocal = false;
      return this.model;
    },
  },
};
</script>

<style lang="scss">
  .grape-send__stripe-add-card-form {
    max-height: 38.4px;
    overflow: hidden;
    padding: .7rem !important;
    border-radius: 5px;
    background-color: #ffffff;
    transition: all .3s ease;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0 rgba(0,0,0,.15);

    &.error {
      border: 1px solid rgba(var(--vs-danger),1) !important;
    }
  }

  .grape-send__stripe-add-card-form.StripeElement--focus {
    border: 1px solid rgba(var(--vs-primary),1) !important;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
  }

  .grape-send__stripe-add-card-form.StripeElement--invalid {
    border: 1px solid rgba(var(--vs-danger),1) !important;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
  }
</style>
