import { loadStripe } from '@stripe/stripe-js';
/**
 * Stripe service
 * @class StripeService
 */
class StripeService {
  constructor() {
    this.loadStripe();
  }

  async loadStripe() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || '');
    this.elements = this.stripe.elements();
  }
}

const singletonInstance = new StripeService();

/**
 * stripe service instance
 * @exports api/stripe-service
 * */
export default singletonInstance;
