<template>
  <vx-card no-shadow>
    <vue-element-loading
      :active="showCardLoading && !preventAppLoading"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <div>
      <vs-table
        :data="paymentMethods"
        :noDataText="$t('NoDataText')"
        stripe>
        <template slot="header">
          <div class="flex w-full justify-between items-center mb-5">
            <h2>
              {{ $t('CreditCardsSectionTitle') }}
            </h2>

            <div class="desktop-button-add-card">
              <vs-button @click="showAddCard=true">
                {{ $t('AddNewCardButton') }}
              </vs-button>
            </div>
          </div>
        </template>

        <template  slot-scope="{data}">
          <div class="saved-pay-cards-all-main">
            <div
              v-for="(pm) in data"
              :key="pm.id"
              class="single-pay-card-wrapper"
              :class="(pm.defaultPaymentMethod) ? 'is-default-pay-card' : 'not-default-pay-card'">
              <div class="pay-card-default-wrapper">
                <div
                  v-if="pm.defaultPaymentMethod"
                  class="pay-card-is-default">
                  {{ $t('$General.Default') | lowercase }}
                </div>
              </div>
              <div class="pay-card-details-brand-logo-wrapper">
                <div
                  v-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `American Express`"
                  class="pay-card-brand pay-card-is-american-express">
                  <img
                    src="@/assets/img/card-brands/card-brand-american-express.png"
                    alt="American Express Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `Diners Club`"
                  class="pay-card-brand pay-card-is-diners-club">
                  <img
                    src="@/assets/img/card-brands/card-brand-diners-club.png"
                    alt="Diners Club Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `Discover`"
                  class="pay-card-brand pay-card-is-discover" >
                  <img
                    src="@/assets/img/card-brands/card-brand-discover.png"
                    alt="Discover Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `JCB`"
                  class="pay-card-brand pay-card-is-jcb">
                  <img
                    src="@/assets/img/card-brands/card-brand-jcb.png"
                    alt="JCB Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `MasterCard`"
                  class="pay-card-brand pay-card-is-mastercard">
                  <img
                    src="@/assets/img/card-brands/card-brand-mastercard.png"
                    alt="MasterCard Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `UnionPay`"
                  class="pay-card-brand pay-card-is-unionpay">
                  <img
                    src="@/assets/img/card-brands/card-brand-unionpay.png"
                    alt="UnionPay Card Brand Logo">
                </div>
                <div
                  v-else-if=" $t(`$Stripe.$Card.$Brand.${pm.card.brand}`)  === `Visa`"
                  class="pay-card-brand pay-card-is-visa">
                  <img
                    src="@/assets/img/card-brands/card-brand-visa.png"
                    alt="Visa Card Brand Logo">
                </div>
                <div
                  v-else
                  class="pay-card-brand pay-card-is-unknown">
                  <img
                    src="@/assets/img/card-brands/card-brand-unknown.png"
                    alt="Unknown Card Brand Logo">
                </div>
              </div>
              <div class="pay-card-details-text">
                <div class="pay-card-details-brand-name">
                  {{ $t(`$Stripe.$Card.$Brand.${pm.card.brand}`) }}
                  <span> {{ $t('$General.CardEnding') }}:</span> {{ pm.card.last4 }}
                </div>
                <div class="pay-card-details-name">
                  <span>{{ $t('$Components.$AddCreditCard.CardholderName') }}:
                  </span> {{ pm.billing_details.name }}
                </div>
                <div class="pay-card-details-expiration">
                  <span>{{ $t('$General.ExpiryDate') }}:
                  </span> {{ `${pm.card.exp_month}/${pm.card.exp_year}` | card_exp }}
                </div>
              </div>
              <div class="pay-card-buttons-wrapper">
                <div class="pay-card-buttons-container-">
                  <vs-button
                    v-if="!pm.defaultPaymentMethod"
                    size="small"
                    @click="makeCardAsDefault(pm.id)">
                    {{ $t('MakeCardAsDefault') }}
                  </vs-button>
                  <vs-button
                    class="ml-3"
                    color="danger"
                    size="small"
                    @click="confirmRemoveCreditCard(pm)">
                    {{ $t('$General.Delete') }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </vs-table>

        <div class="mobile-button-add-card">
          <vs-button @click="showAddCard=true">
            {{ $t('AddNewCardButton') }}
          </vs-button>
        </div>

    </div>

    <vs-popup
      id="add_card_modal_flj6ffe64"
      :title="$t('AddCardModalTitle')"
      :active.sync="showAddCard">
      <transition name="zoom-fade">
        <add-credit-card
          v-if="showAddCard"
          :card-error-prop="stripeCardError"
          :validating-card="showCardLoading"
          @add-card="addCreditCard"
          @cancel="showAddCard=false"
          @change="stripeCardError = ''"/>
      </transition>
    </vs-popup>
  </vx-card>
</template>

<script>
import AddCreditCard from '@/views/modules/_components/AddCreditCard.vue';
import tenantService from '@/api/modules/tenant.service';

/**
 * Component for allow the user view and edit his payment methods
 *
 * @module views/modules/user/user-billing/UserAccountBillingPaymentMethods.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [paymentMethods=[]] - user payment methods
 * @vue-data {boolean} [showCardLoading=false] - show card loading
 * @vue-data {boolean} [showAddCard=false] - indicate if show or no the modal to add a new card
 * @vue-computed {boolean} formIsChanged - indicate if the form was changed
 * @vue-event {void} fetchData - fetch component data
 * @vue-event {void} created - hook to fetch billing information
 * @vue-event {void} fetchBillingInformation - fetch billing information
 * @vue-event {void} addCreditCard - add new credit card to user
 * @vue-event {void} confirmRemoveCreditCard - confirmation to remove a credit card
 * @vue-event {void} removeCreditCard - remove one credit card from auth user
 * @vue-event {void} makeCardAsDefault - mark one credit card as default
 */
export default {
  name: 'UserAccountBillingPaymentMethods',
  i18n: {
    messages: {
      en: {
        BillingTaxId: 'Billing tax ID',
        CreditCardsSectionTitle: 'Credit cards',
        AddNewCardButton: 'Add card',
        LastFourDigits: 'Last 4 Digits',
        CardType: 'Card type',
        MakeCardAsDefault: 'Make default',
        PrefillWithBusinessDetails: 'Prefill with business details',
        AddCardModalTitle: 'Add a card',
        RemoveCreditCardConfirmationTitle: 'Confirm remove credit card',
        RemoveCreditCardConfirmationMsg: 'You are about to remove the credit card: {card}',
        AddedCreditCardDialogNtf: {
          Title: 'Add credit card',
          text: 'Credit card added successfully',
        },
        NoDataText: 'You don\'t have payment methods yet',
        MakeCreditCardDefaultDialogNtf: {
          Title: 'Updating default payment method',
          text: 'Payment method updated successfully',
        },
        DeleteCreditCardDefaultDialogNtf: {
          Title: 'Deleting credit card',
          text: 'Credit card deleted successfully',
        },
      },
    },
  },
  components: {
    AddCreditCard,
  },
  data() {
    return {
      paymentMethods: [],
      showCardLoading: false,
      showAddCard: false,
      stripeCardError: '',
    };
  },
  computed: {
    formIsChanged() {
      return Object.keys(this.validationFields).some((key) => this.validationFields[key].changed);
    },
  },
  created() {
    window.history.replaceState({}, null, '/billing/payment-methods');
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.showCardLoading = true;
      await this.fetchBillingInformation();
      this.showCardLoading = false;
    },
    async fetchBillingInformation() {
      const resp = await tenantService.getPaymentMethods();
      this.paymentMethods = resp.data;
    },
    async addCreditCard(payload) {
      this.showCardLoading = true;

      try {
        const resp = await tenantService.addCreditCard(payload);
        this.paymentMethods = resp.data;
        this.$vs.loading.close();

        this.$showSuccessActionNotification({
          title: this.$t('AddedCreditCardDialogNtf.Title'),
          text: this.$t('AddedCreditCardDialogNtf.text'),
        });
        this.showAddCard = false;
      } catch (err) {
        if (err.response.data && err.response.data.type === 'StripeCardError') {
          this.stripeCardError = err.response.data.message;
        } else {
          throw err;
        }
      } finally {
        this.showCardLoading = false;
      }
    },
    confirmRemoveCreditCard(paymentMethod) {
      const cardName = paymentMethod.billing_details.name;
      const cardBrand = this.$t(`$Stripe.$Card.$Brand.${paymentMethod.card.brand}`);
      const cardNumber = `••••${paymentMethod.card.last4}`;
      const card = `${cardName} - ${cardBrand} ${cardNumber}`;

      this.$showConfirmDeleteDialog({
        title: this.$t('RemoveCreditCardConfirmationTitle'),
        text: this.$t('RemoveCreditCardConfirmationMsg', { card }),
        accept: () => {
          this.removeCreditCard(paymentMethod.id);
        },
        acceptText: this.$t('$General.Delete'),
      });
    },
    async removeCreditCard(paymentMethodId) {
      this.showCardLoading = true;
      const resp = await tenantService.removePaymentMethod(paymentMethodId);
      this.paymentMethods = resp.data;
      this.showCardLoading = false;

      this.$showSuccessActionNotification({
        title: this.$t('DeleteCreditCardDefaultDialogNtf.Title'),
        text: this.$t('DeleteCreditCardDefaultDialogNtf.text'),
      });
    },
    async makeCardAsDefault(paymentMethodId) {
      this.showCardLoading = true;
      const resp = await tenantService.updateDefaultPaymentMethod(paymentMethodId);
      this.paymentMethods = resp.data;
      this.showCardLoading = false;

      this.$showSuccessActionNotification({
        title: this.$t('MakeCreditCardDefaultDialogNtf.Title'),
        text: this.$t('MakeCreditCardDefaultDialogNtf.text'),
      });
    },
  },
};
</script>

<style lang="scss">
#add_card_modal_flj6ffe64.con-vs-popup {
  .vs-popup {
    max-width: 450px;
  }
}

.mobile-button-add-card {
  display: none;
}

.vs-con-tbody.vs-table--tbody {
  border: 0px;

  .vs-table.vs-table--tbody-table{

    min-width: auto; }

  .saved-pay-cards-all-main {
    display: flex;
    flex-wrap: wrap;
    transition: 0.5s;
    background: #fff;

    .pay-card-default-wrapper {
      opacity: 0;
      transition: 1s;
      position: absolute;
      left: -12px;
      top: -3px;
      z-index: 1;
      overflow: hidden;
      width: 110px;
      height: 75px;
      text-align: left;
    }

    /* .pay-card-default-wrapper {
      opacity: 0;
      transition: 3s;
      position: absolute;
      left: -8px; top: -8px;
      z-index: 1;
      overflow: hidden;
      width: 75px; height: 75px;
      text-align: left;
    } */

    .single-pay-card-wrapper.not-default-pay-card {
      background: #fff;
      border: 3px solid transparent;
    }

    .single-pay-card-wrapper.is-default-pay-card {
      border: 3px solid rgba(var(--vs-success), 0.2);
      background: rgba(var(--vs-success), 0.1);

      .pay-card-default-wrapper {
        opacity: 1;
        border-radius: 10px 5px;

        .pay-card-is-default {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
          line-height: 36px;
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          width: 120px;
          display: block;
          border-radius: 5px 0px 5px 0px;
          padding-left: 10px;
          background: rgba(var(--vs-success), 1);
          /* box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1); */
          position: absolute;
          top: 0px;
          left: -10px;
        }

        .pay-card-is-default::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 100%;
          z-index: -1;
          border-left: 6px solid transparent;
          border-right: 6px solid rgba(var(--vs-success), 0.5);
          border-bottom: 6px solid transparent;
          border-top: 6px solid rgba(var(--vs-success), 0.5);
        }
      }

      /* .pay-card-is-default {
            font-size: 12px;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            width: 100px;
            display: block;
            background: rgba(var(--vs-success),1);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px; left: -21px;
          }
          .pay-card-is-default::before {
            content: "";
            position: absolute; left: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid rgba(var(--vs-success),0.5);
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid rgba(var(--vs-success),0.5);
          }
          .pay-card-is-default::after {
            content: "";
            position: absolute; right: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid rgba(var(--vs-success),0.5);
            border-bottom: 3px solid transparent;
            border-top: 3px solid rgba(var(--vs-success),0.5);
          }
        } */
    }

    .single-pay-card-wrapper {
      display: flex;
      width: calc(50% - 20px);
      padding: 40px;
      margin: 10px;
      border-radius: 0.5rem;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      transition: 1s;

      .pay-card-details-brand-logo-wrapper {
        width: 100px;
      }

      .pay-card-details-text {
        width: 100%;
        padding-left: 20px;
      }

      .pay-card-buttons-wrapper {
        position: absolute;
        right: 0px;
        bottom: 0px;
        padding: 0px 10px 10px 0px;
      }
    }

    .single-pay-card-wrapper:hover {
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }
  }
}

.pay-card-brand {
  padding: 10px;
}

.pay-card-brand img {
  max-width: 80px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.pay-card-details-brand-name,
.pay-card-details-name,
.pay-card-details-expiration {
  font-weight: 800;
}

.pay-card-details-brand-name span,
.pay-card-details-name span,
.pay-card-details-expiration span {
  font-weight: 400;
}

.vs-button.small {
  font-size: 0.9em;
}

@media only screen and (max-width: 900px) {
  .single-pay-card-wrapper {
      width: calc(100% - 20px) !important;

      .pay-card-details-text {
        margin-bottom: 15px;
      }

  }

}

@media only screen and (max-width: 530px) {
  .desktop-button-add-card {
    display: none;
  }

  .mobile-button-add-card {
    display: block;
    margin-top: 20px;
    margin-bottom: 60px;

    button {
      margin: auto;
      left: 0px;
      right: 0px;
      position: absolute;
    }
  }

  .single-pay-card-wrapper {
      display: block !important;
      padding: 10px !important;

      .pay-card-details-brand-logo-wrapper {
        width: 100% !important;
        margin-top: 30px;
        img {
          margin: auto;
        }
      }

      .pay-card-details-text {
        margin-bottom: 45px;
        text-align: center;
        padding-left: 0px !important;
      }

      .pay-card-buttons-wrapper {

          text-align: center;
          width: 100%;

        .vs-button.small {
          font-size: 0.7em;
          padding: 0.5rem 0.5rem;
          margin-left: 0.75rem !important;
        }
      }
  }

}
</style>
